import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import TcVerifyComponent from "./routes/tc-dogrulama/tc-dogrulama";
import Home from "./routes/home";
import TcBilgiDogrulama from "./routes/tc-bilgi-dogrulama/tc-bilgi-dogrulama";
import KelimeHarfSayisiHesaplama from "./routes/kelime-hesaplama/kelime-hesaplama";
import Main from "./routes/Main/main";



const RouterComponent = () => {
    return (
        <Router>
            <header id="fh5co-header" role="banner">
                <div className="container">
                    <div id="fh5co-logo">
                        <Link to="/">
                            <img src="./logo.png" alt="Work Logo" />
                        </Link>
                    </div>
                    <div id="fh5co-main-nav">
                        <nav id="fh5co-nav" role="navigation">
                            <ul style={{ paddingTop: 20 }}>
                                <li className="fh5co">
                                    <Link to="/kura-cek">Kura Çek / Çekiliş Yap</Link>
                                </li>
                                <li className="fh5co">
                                    <Link to="/tc-kimlik-no-dogrulama">Tc Kimlik No Doğrulama</Link>
                                </li>
                                <li className="fh5co">
                                    <Link to="/tc-kimlik-no-bilgileri-dogrulama">
                                        Tc Kimlik No Bilgileri Doğrulama
                                    </Link>
                                </li>
                                <li className="fh5co">
                                    <Link to="/kelime-harf-sayisi-hesaplama">Kelime/Harf Sayısı Hesaplama</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>

            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/kura-cek" element={<Home />} />
                <Route path="/tc-kimlik-no-dogrulama" element={<TcVerifyComponent />} />
                <Route path="/tc-kimlik-no-bilgileri-dogrulama" element={<TcBilgiDogrulama />} />
                <Route path="/kelime-harf-sayisi-hesaplama" element={<KelimeHarfSayisiHesaplama />} />

            </Routes>
        </Router>
    );
};

export default RouterComponent;
