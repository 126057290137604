import React from 'react';

import '../../App.css'

const Main = () => {
    return (
        <main role="main">

            <div id="fh5co-intro">
                <div className="container">
                    <h1>Hesaplaa.com Hesaplama Araçları</h1>
                    <div className="row">
                        <div className="col-md-12 fh5co-intro-sub">
                            <p>Hesaplaa.com webmasterler, öğrenciler vs. internet gezginleri için geliştirilmiş bir
                                platformdur. Menülerden ihtiyacınız olan servise ulaşabilirsiniz.</p>
                            <p>İşe yarar servis öneriniz varsa bize yazmaktan çekinmeyin :)</p>
                            <h3 style={{color: '#a94442', textAlign: 'center'}}>Tüm Servisler</h3>
                            <p style={{textAlign: 'center'}}><a href="/kura-cek">Kura Çek / Çekiliş Yap</a></p>
                            <p style={{textAlign: 'center'}}><a href="/tc-kimlik-no-dogrulama">Tc Kimlik No
                                Doğrulama</a></p>
                            <p style={{textAlign: 'center'}}><a href="/tc-kimlik-no-bilgileri-dogrulama">Tc Kimlik No
                                Bilgileri Doğrulama</a></p>
                            <p style={{textAlign: 'center'}}><a href="/kelime-harf-sayisi-hesaplama">Kelime/Harf Sayısı
                                Hesaplama</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{textAlign: 'center', display: 'block'}}>
            </div>
            <footer id="fh5co-footer" role="contentinfo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-push-6 col-md-6">
                            <ul className="fh5co-footer-social">
                                <li><a href="/">Yasal Uyarı</a></li>
                                <li><a href="#">İletişim</a></li>
                            </ul>
                            <p className="fh5co-copyright">
                                <small>(c) 2017 <a href="/">hesaplaa.com</a>. All Rights Reserved.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <a href="#" className="fh5co-gotop" style={{display: 'none'}}>
                <i className="ti-shift-left"></i>
            </a>

        </main>
    );
};

export default Main;
