import React from 'react';
import RouterComponent from "./router";


function App() {
    return (
        <div>
            <RouterComponent />
        </div>
    );
}

export default App;
