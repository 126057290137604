import React from 'react';
import '../../App.css'
const TcBilgiDogrulama = () => {
    return (
        <main role="main">


            <div id="fh5co-intro">
                <div className="container">
                    <h1>Tc Kimlik Doğrulama</h1>
                    <div className="row" style={{display:"block"}}>
                        <div className="col-md-12 fh5co-intro-sub">
                            <form method="post">
                                <label className="col-lg-2">İsim</label>:
                                <input type="text" name="name"/><br/>
                                <label className="col-lg-2">Soyisim</label>:
                                <input type="text" name="surname"/><br/>
                                <label className="col-lg-2">Tc No</label>:
                                <input type="text" name="ssn"/><br/>
                                <label className="col-lg-2">Doğum Yılı</label>:
                                <input type="text" name="birthday"/><br/>
                                <input type="submit" value="Kontrol Et"/>
                            </form>
                            <h4></h4>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{textAlign: 'center', display: 'block'}} data-width="1000" data-include-parent="true"
                 className="fb-comments"
                 data-href="https://www.hesaplaa.com/kura-cek" data-numposts="5" data-order-by="reverse_time">
                    <span style={{verticalAlign: 'bottom', width: '1000px', height: '714px'}}>
                        <iframe
                            name="fb-comments"
                            width="1000px"
                            height="100px"
                            data-testid="fb:comments Facebook Social Plugin"
                            title="fb:comments Facebook Social Plugin"
                            frameBorder="0"
                            allowTransparency="true"
                            allowFullScreen="true"
                            scrolling="no"
                            allow="encrypted-media"
                            src="https://www.facebook.com/v2.11/plugins/comments.php?app_id=568578073310421&amp;container_width=1724&amp;height=100&amp;href=https%3A%2F%2Fwww.hesaplaa.com%2Fkura-cek&amp;locale=tr_TR&amp;numposts=5&amp;order_by=reverse_time&amp;sdk=joey&amp;version=v2.11&amp;width=1000"
                            style={{border: 'none', visibility: 'visible', width: '1000px', height: '714px'}}
                        ></iframe>
                    </span>
            </div>

            <footer id="fh5co-footer" role="contentinfo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-push-6 col-md-6">
                            <ul className="fh5co-footer-social">
                                <li><a href="/">Yasal Uyarı</a></li>
                                <li><a href="#">İletişim</a></li>
                            </ul>
                            <p className="fh5co-copyright">
                                <small>(c) 2017 <a href="/">hesaplaa.com</a>. All Rights Reserved.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>

            {/* Go To Top */}
            <a href="#" className="fh5co-gotop"><i className="ti-shift-left"></i></a>

        </main>
    );
};

export default TcBilgiDogrulama;
