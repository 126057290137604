import {useState} from 'react';
import '../App.css';

function Home() {
    const [items, setItems] = useState("");
    const [winners, setWinners] = useState(9);
    const [results, setResults] = useState([]);

// Convert both keys and values to strings
    const handleSubmit = (e) => {
        e.preventDefault();

        let itemList = items.split(/[\n,]+/).map(item => item.trim()).filter(item => item);

        // Shuffle the items randomly
        let shuffledItems = itemList.sort(() => 0.5 - Math.random());

        // No manual mapping, just use the shuffled items directly
        let finalItems = [...shuffledItems];

        const winnerCount = Math.min(winners, finalItems.length);
        const selectedWinners = finalItems.slice(0, winnerCount);

        setResults(selectedWinners);
    };


    return (
        <div className="container">
            <main role="main">
                <div id="fh5co-intro">
                    <div className="container">
                        <h1>Çekiliş Yap / Kura Çekimi / Çekiliş Sitesi</h1>
                        <h3>Listeyi oluştururken alt alta ya da virgülle ayırınız.</h3>
                        <div className="row">
                            <div className="col-md-12 fh5co-intro-sub">
                                <form method="post" style={{display: "flow"}} onSubmit={handleSubmit}>
                                    <label>
                                        Liste:
                                        <textarea
                                            name="text"
                                            className="textinput"
                                            value={items}
                                            onChange={(e) => setItems(e.target.value)}
                                        ></textarea>
                                    </label>
                                    <br/>
                                    <label>
                                        Kazananlar:
                                        <input
                                            type="text"
                                            name="winner"
                                            value={winners}
                                            onChange={(e) => setWinners(e.target.value)}
                                        />
                                    </label>

                                    <label>
                                        Yedek Kazananlar:
                                        <input type="text" name="sWinner" defaultValue="0"/>
                                    </label>

                                    <input type="submit" value="Kura Çek"/>
                                </form>
                            </div>
                        </div>
                        {results.length > 0 && (
                            <div className="results">
                                <h2>Talihliler</h2>
                                {results.map((winner, index) => (
                                    <h6 key={index}>
                                        {index + 1}.) {winner}
                                    </h6>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div style={{textAlign: 'center', display: 'block'}} data-width="1000" data-include-parent="true"
                     className="fb-comments"
                     data-href="https://www.hesaplaa.com/kura-cek" data-numposts="5" data-order-by="reverse_time">
                    <span style={{verticalAlign: 'bottom', width: '1000px', height: '714px'}}>
                        <iframe
                            name="fb-comments"
                            width="1000px"
                            height="100px"
                            data-testid="fb:comments Facebook Social Plugin"
                            title="fb:comments Facebook Social Plugin"
                            frameBorder="0"
                            allowTransparency="true"
                            allowFullScreen="true"
                            scrolling="no"
                            allow="encrypted-media"
                            src="https://www.facebook.com/v2.11/plugins/comments.php?app_id=568578073310421&amp;container_width=1724&amp;height=100&amp;href=https%3A%2F%2Fwww.hesaplaa.com%2Fkura-cek&amp;locale=tr_TR&amp;numposts=5&amp;order_by=reverse_time&amp;sdk=joey&amp;version=v2.11&amp;width=1000"
                            style={{border: 'none', visibility: 'visible', width: '1000px', height: '714px'}}
                        ></iframe>
                    </span>
                </div>

                <footer id="fh5co-footer" role="contentinfo">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-push-6 col-md-6">
                                <ul className="fh5co-footer-social">
                                    <li><a href="/">Yasal Uyarı</a></li>
                                    <li><a href="#">İletişim</a></li>
                                </ul>
                                <p className="fh5co-copyright">
                                    <small>(c) 2017 <a href="/">hesaplaa.com</a>. All Rights Reserved.</small>
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>

                <a href="#" className="fh5co-gotop" style={{display: 'none'}}><i className="ti-shift-left"></i></a>
            </main>
        </div>
    );
}

export default Home;
