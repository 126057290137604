import React from 'react';

const KelimeHarfSayisiHesaplama = () => {
    return (
        <main role="main">
            <div id="fh5co-intro">
                <div className="container">
                    <h1>Kelime/Harf Sayısı Hesaplama</h1>
                    <div className="row">
                        <div className="col-md-12 fh5co-intro-sub">
                            <form method="post">
                                <textarea name="article" className="textinput"></textarea><br />
                                <input type="submit" value="Hesapla" />
                            </form>
                            <h4></h4>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: "center", display: "block" }}>
                <div
                    className="fb-comments fb_iframe_widget fb_iframe_widget_fluid_desktop"
                    data-width="1000"
                    data-include-parent="true"
                    data-href="https://www.hesaplaa.com/kelime-harf-sayisi-hesaplama"
                    data-numposts="5"
                    data-order-by="reverse_time"
                    fb-xfbml-state="rendered"
                >
                    <iframe
                        title="Facebook Comments Plugin"
                        name="f634036e4e985638a"
                        width="1000px"
                        height="210px"
                        scrolling="no"
                        allowTransparency="true"
                        allow="encrypted-media"
                        frameBorder="0"
                        src="https://www.facebook.com/v2.11/plugins/comments.php?app_id=568578073310421&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Dfe22140f6bbcdf446%26domain%3Dwww.hesaplaa.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.hesaplaa.com%252Ff00561cd666a53090%26relation%3Dparent.parent&container_width=1728&height=210&href=https%3A%2F%2Fwww.hesaplaa.com%2Fkelime-harf-sayisi-hesaplama&locale=tr_TR&numposts=5&order_by=reverse_time&s..."></iframe>
                </div>
            </div>
            <footer id="fh5co-footer" role="contentinfo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-push-6 col-md-6">
                            <ul className="fh5co-footer-social">
                                <li><a href="/">Yasal Uyarı</a></li>
                                <li><a href="#">İletişim</a></li>
                            </ul>
                            <p className="fh5co-copyright">
                                <small>(c) 2017 <a href="/">hesaplaa.com</a>. All Rights Reserved.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <a href="#" className="fh5co-gotop"><i className="ti-shift-left"></i></a>
        </main>
    );
};

export default KelimeHarfSayisiHesaplama;
